<template>
  <Layout>
    <div class="regcontainer">
      <Header
        :title="content.noniab_o_title"
        :back="false"
        regulation="lgpd"
      />
      <h1 :dir="dir">
        {{ content.noniab_o_title }}
      </h1>
      <div
        class="text"
        :dir="dir"
        v-html="content.noniab_o_content"
      />
      <div
        class="list"
        :dir="dir"
      >
        <div class="list_item">
          <div class="list_handle">
            <div
              :class="[
                'list_expand',
                nonIabExpanded === true ? 'expanded' : '',
              ]"
              @touchend="expand($event)"
              @click="expand($event)"
            >
              >
            </div>
            <div class="list_title">
              {{ content.p_title }}
            </div>
            <div
              v-if="!$compliance.legacyMode"
              :class="[
                'list_checkbox',
                nonIabConsented === true ? 'checked' : '',
              ]"
              @touchend="toggleConsent($event)"
              @click="toggleConsent($event)"
            >
              cb_vendors
            </div>
          </div>
          <div
            :class="['list_expanded', nonIabExpanded === true ? 'expand' : '']"
          >
            <Loading v-if="nonIabVendors === null" />
            <Vendor
              v-for="(vendor, i) in nonIabVendors"
              :key="i"
              :vendor="vendor"
              :vendor-details="true"
              :non-i-a-b="true"
              @consented="updateNonIABConsent"
            />
          </div>
        </div>
      </div>
      <Footer
        module="lgpd"
        screen="options"
      />
    </div>
  </Layout>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Header from '@components/Header.vue'
import Footer from '@components/Footer.vue'
import Vendor from '@components/tcf20/Vendor.vue'
import Loading from '@components/Loading.vue'

export default {
  metaInfo() {
    return {
      title: 'More Options | Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'More Options | Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    Footer,
    Loading,
    Vendor,
  },
  data() {
    return {
      content: {},
      showVendorsBatchSize: 10,
      totalVendorsToShow: 10,
      nonIabExpanded: false,
      nonIabConsented: false,
      nonIabVendors: null,
      startTime: null,
      endTime: null,
      dir: 'ltr',
    }
  },
  computed: {
    vendorsBatch() {
      return this.iabVendors !== null
        ? this.iabVendors.slice(0, this.totalVendorsToShow)
        : null
    },
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.nonIabConsented = this.$compliance.nonIAB.hasConsented()

    // EXPAND vendors
    this.nonIabExpanded = true
    // retrieve & replace screen option data from GVL & set initial consents
    this.retrieveNIABVendors()
    this.dir = this.$compliance.dir
  },
  methods: {
    updateNonIABConsent() {
      this.nonIabConsented = this.$compliance.nonIAB.hasConsented()
    },
    retrieveNIABVendors() {
      if (this.nonIabVendors === null) {
        const nv = this.$compliance.nonIAB.getVendors()
        if (this.$compliance.legacyMode) {
          this.nonIabVendors = nv
        } else {
          this.nonIabVendors = Object.values(nv).map((el) => {
            return {
              ...el,
              consent: this.$compliance.nonIAB.getVendorConsent(el.id),
            }
          })
        }
      }
    },
    expand(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.nonIabExpanded = !this.nonIabExpanded
      // retrieve & replace screen option data from GVL & set initial consents
      this.retrieveNIABVendors()
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[LGPD] show vendors',
        timestamp: new Date(),
      })
    },
    toggleConsent(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.nonIabConsented = !this.nonIabConsented
      if (this.nonIabVendors === null) this.retrieveNIABVendors() // first fetch vendors if we haven't yet

      if (this.nonIabConsented) {
        this.$compliance.nonIAB.setVendorConsent('all')
        if (!this.$compliance.legacyMode) {
          this.nonIabVendors = Object.values(this.nonIabVendors).map((el) => {
            return { ...el, consent: true }
          })
        }
      } else {
        this.$compliance.nonIAB.unsetVendorConsent('all')
        if (!this.$compliance.legacyMode) {
          this.nonIabVendors = Object.values(this.nonIabVendors).map((el) => {
            return { ...el, consent: false }
          })
        }
      }
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[LGPD] toggle consent',
        timestamp: new Date(),
      })
    },
  },
}
</script>

<style lang="scss">
.regcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .text {
    padding: 20px 10px 0 10px;
    margin: 0;
    font-size: 1em;
    color: #4c5d6a;
    text-align: center;
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  h1 {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 70px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .list {
    padding: 0 10px;
    margin: 20px 0 130px 0;
    background: #fff;
    @media screen and (min-width: 768px) {
      margin: 20px 0 200px 0;
    }

    h2 {
      font-size: 1.6em;
      color: #626d77;
      border-bottom: 1px solid #b8b8b8;
    }

    h3 {
      margin: 10px 0 0 5px;
      font-size: 1.2em;
      color: #626d77;
    }
    .list_item {
      margin: 0 0 8px 0;
      color: #4c5d6a;
      background: #fff;

      .list_handle {
        display: flex;
        align-items: center;

        .list_expand {
          position: relative;
          flex-basis: 40px;
          justify-self: start;
          width: 40px;
          height: 40px;
          overflow: hidden;
          text-indent: -9999px;
          background-image: url('../../../assets/images/btn_expand.png');
          background-repeat: no-repeat;
          background-size: contain;
          transition: transform 0.5s;
          transform: rotate(-90deg);
          &.expanded {
            transform: rotate(0);
          }
        }
        .list_title {
          flex-basis: calc(100% - 70px);
          align-self: center;
          font-size: 1.2em;
          line-height: 1.2em;
          @media screen and (min-width: 768px) {
            font-size: 1.8em;
          }
          @media screen and (min-width: 1024px) {
            font-size: 2.1em;
          }
        }
        .list_checkbox {
          flex-basis: 40px;
          width: 40px;
          height: 30px;
          text-indent: -9999px;
          background-image: url('../../../assets/images/btn_checkbox_empty.png');
          background-repeat: no-repeat;
          background-size: contain;
          &.checked {
            background-image: url('../../../assets/images/btn_checkbox.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }

    .list_expanded {
      display: none;
      height: 0;
      padding: 0 0 0 20px;
      margin: 5px 0 5px 0;
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.2em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8em;
      }
      &.expand {
        display: block;
        height: auto;
      }
      .list_more {
        color: #688194;
        text-decoration: underline;
      }
    }
  }
}
.ru .regcontainer h1 {
  font-size: 1.6em;
}
</style>
